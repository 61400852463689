import React, { useState, useEffect } from "react";
export const checkoutContext = React.createContext({});

let getCart = {};

const Provider = props => {
    const [adviser, setAdviser] = useState(false);
    useEffect(() => {
        if (location && location.search) {
            if (location.search.slice(1)) {
                setAdviser(location.search.slice(1));
            }
        }
    }, []);

    if (typeof localStorage !== "undefined") {
        getCart = () => {
            let store = localStorage.getItem("cart");
            return store !== null ? JSON.parse(store) : {};
        };
    } else {
        getCart = () => {
            return {};
        };
    }

    const [cart, setCart] = useState(getCart);
    const [checkoutForm, setCheckoutForm] = useState(false);
    const [filter, setFilter] = useState(false);

    useEffect(() => {
        typeof localStorage !== "undefined" &&
            localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    return (
        <checkoutContext.Provider
            value={{
                setCart,
                setCheckoutForm,
                setFilter,
                cart,
                checkoutForm,
                filter,
                adviser,
            }}
        >
            {props.children}
        </checkoutContext.Provider>
    );
};

export default ({ element }) => <Provider>{element}</Provider>;
