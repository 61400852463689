/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./src/styles/bootstrap.min.css";
const Provider = require("./src/context/checkoutContextProvider").default;
exports.wrapRootElement = Provider;
