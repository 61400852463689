module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PKVKSCN","includeInDevelopment":true,"gtmAuth":"","gtmPreview":"","dataLayerName":"dataLayer"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kapitol Gold","short_name":"Kapitol Gold","start_url":"/","background_color":"#fff","theme_color":"#B29B5B","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ac020a05e8b7d2a899f14d45675dd637"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
