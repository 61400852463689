// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-e-shop-cart-js": () => import("./../../../src/pages/e-shop/cart.js" /* webpackChunkName: "component---src-pages-e-shop-cart-js" */),
  "component---src-pages-e-shop-confirmation-js": () => import("./../../../src/pages/e-shop/confirmation.js" /* webpackChunkName: "component---src-pages-e-shop-confirmation-js" */),
  "component---src-pages-e-shop-delivery-js": () => import("./../../../src/pages/e-shop/delivery.js" /* webpackChunkName: "component---src-pages-e-shop-delivery-js" */),
  "component---src-pages-kariera-poradce-pro-investicni-kovy-js": () => import("./../../../src/pages/kariera-poradce-pro-investicni-kovy.js" /* webpackChunkName: "component---src-pages-kariera-poradce-pro-investicni-kovy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-thank-you-career-js": () => import("./../../../src/pages/thank-you-career.js" /* webpackChunkName: "component---src-pages-thank-you-career-js" */),
  "component---src-pages-thank-you-contact-js": () => import("./../../../src/pages/thank-you-contact.js" /* webpackChunkName: "component---src-pages-thank-you-contact-js" */),
  "component---src-pages-thank-you-gold-js": () => import("./../../../src/pages/thank-you-gold.js" /* webpackChunkName: "component---src-pages-thank-you-gold-js" */),
  "component---src-pages-thank-you-platinum-js": () => import("./../../../src/pages/thank-you-platinum.js" /* webpackChunkName: "component---src-pages-thank-you-platinum-js" */),
  "component---src-pages-thank-you-silver-js": () => import("./../../../src/pages/thank-you-silver.js" /* webpackChunkName: "component---src-pages-thank-you-silver-js" */),
  "component---src-pages-vyvoj-cen-js": () => import("./../../../src/pages/vyvoj-cen.js" /* webpackChunkName: "component---src-pages-vyvoj-cen-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-coin-js": () => import("./../../../src/templates/coin.js" /* webpackChunkName: "component---src-templates-coin-js" */),
  "component---src-templates-eshop-category-js": () => import("./../../../src/templates/eshopCategory.js" /* webpackChunkName: "component---src-templates-eshop-category-js" */),
  "component---src-templates-eshop-js": () => import("./../../../src/templates/eshop.js" /* webpackChunkName: "component---src-templates-eshop-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

